'use client';

import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { ROUTES } from '@/config/router';
import { Link } from '@/navigation';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const tSystem = useTranslations('system');

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <>
      <h1>{tSystem('error.title')}</h1>
      <h5>{tSystem('error.errorHint1')}</h5>
      {error.digest ? (
        <p>
          {tSystem('error.errorDigest')}: <code>{error.digest}</code>
        </p>
      ) : null}

      <div>
        <button onClick={() => reset()}>
          {tSystem('error.refreshThePage')}
        </button>
        <Link href={ROUTES.home} passHref>
          <button>{tSystem('links.backToHomepage')}</button>
        </Link>
      </div>
    </>
  );
}
