import { useLocale } from 'next-intl';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { locales, localePrefix } from './i18n';

export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({
    locales,
    localePrefix,
  });

export const publicPages = ['/'];

export const useLocaleSuffix = () => {
  const curLocale = useLocale();
  return curLocale === 'en' ? 'en_us' : 'vi_vn';
};
